<script setup lang="ts">
import { useGeosearchStore } from '~/stores/geosearchAdvanced'

const geosearch = useGeosearchStore()

const mapRef = useMapboxRef('mapMain')

const zoomLevel = computed(() => mapRef.value?.getZoom().toFixed(2))

const isProZoomLevel = computed(() =>
  zoomLevel.value
    && Number(zoomLevel.value) > (geosearch.zoomLevelLimit - 0.5),
  )
</script>

<template>
  <div v-if="geosearch.showZoomLevelOverlay" class="fixed inset-x-0 bottom-0 flex justify-center pb-8">
    <div
      class="text-xs text-muted-foreground bg-background/80 backdrop-blur-sm px-3 py-1 rounded-full border border-border/50 shadow-sm"
    >
      Zoom level: {{ zoomLevel }} / Limit: {{ geosearch.zoomLevelLimit }}
    </div>
  </div>
  <div class="fixed inset-x-0 bottom-0 flex justify-center pb-16">
    <PostHogFeatureFlag name="geomap_zoom_in_limit_non_pro">
      <Alert
        v-if="isProZoomLevel" v-motion-pop variant="default"
        class="w-2/5 bg-primary/30 border-2 border-primary/20 shadow-lg z-60"
      >
        <Icon name="soilicon:zoom-in" class="h-6 w-6 mr-2 " />
        <AlertTitle>Zoom limit reached</AlertTitle>
        <AlertDescription>
          Sign in to upgrade to a Pro account to zoom in further
        </AlertDescription>
      </Alert>
    </PostHogFeatureFlag>
  </div>
</template>
