<script setup lang="ts">
import { useGeosearchStore } from '~/stores/geosearchAdvanced'

// TODO: Work in progress Global Geocoder for use outside of the Geomap component

const geosearch = useGeosearchStore()
const globalGeocoderResult = ref<MapboxGeocoder.Result>()
const mapLoaded = ref(false)
let mapInstance: mapboxgl.Map | null = null

// Setup map with proper callback handling
useMapbox('mainMap', (map) => {
  mapLoaded.value = true
  mapInstance = map
})

watch(() => globalGeocoderResult.value, (result) => {
  if (!result || !mapInstance) { return }

  useLogger().log('global geocoder result', '#map', '#geocoder', {
    globalGeocoderResult: result,
    })

  // Handle the geocoder result
  geosearch.setGeocoderResult(result)

  // Zoom to the location
  if (result.bbox) {
    // If we have a bounding box, fit to it
    mapInstance.fitBounds([
      [result.bbox[0], result.bbox[1]], // southwestern corner
      [result.bbox[2], result.bbox[3]], // northeastern corner
      ], {
      padding: 50,
      duration: 1000,
      })
  }
  else if (result.center) {
    // If we only have a center point, fly to it
    mapInstance.flyTo({
      center: result.center as [number, number],
      zoom: 12,
      duration: 1000,
      })
  }
}, { deep: true })
</script>

<template>
  <div>
    <MapboxCustomGeocoder
      v-model="globalGeocoderResult"
      :options="{
        version: 'v6',
        proximity: 'ip',
        countries: 'gb',
      }"
    />
  </div>
</template>

<style scoped>

</style>
