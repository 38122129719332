<script setup lang="ts">
import {
  BugPlayIcon,
  EyeIcon,
  FilterIcon,
  LocateIcon,
  MapIcon,
  MaximizeIcon,
  PaintbrushIcon,
  WrenchIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from 'lucide-vue-next'
import { useToast } from '~/modules/ui/components/toast'
import { useGeosearchStore } from '~/stores/geosearchAdvanced'

const geosearch = useGeosearchStore()
const mapRef = useMapboxRef('mapMain')

function zoomIn() {
  if (!mapRef.value) {
    return
  }
  mapRef.value.zoomIn()
  useLogger().log('Zoomed in', '#map', { newZoom: mapRef.value.getZoom() })
}

function zoomOut() {
  if (!mapRef.value) {
    return
  }
  mapRef.value.zoomOut()
  useLogger().log('Zoomed out', '#map', { newZoom: mapRef.value.getZoom() })
}

function toggleFullscreen() {
  if (!mapRef.value) {
    return
  }

  if (!document.fullscreenElement) {
    mapRef.value.getContainer().requestFullscreen()
    useLogger().log('Entered fullscreen', '#map')
  }
  else {
    document.exitFullscreen()
    useLogger().log('Exited fullscreen', '#map')
  }
}

// function findMyLocation() {
//   if (!mapRef.value) {
//     return
//   }

//   navigator.geolocation.getCurrentPosition(
//     (position) => {
//       mapRef.value?.flyTo({
//         center: [position.coords.longitude, position.coords.latitude],
//         zoom: 14,
//         essential: true,
//       })
//       useLogger().log('Found user location', '#map', {
//         lat: position.coords.latitude,
//         lng: position.coords.longitude,
//       })
//     },
//     (error) => {
//       useLogger().error('Failed to get location', '#map', { error })
//       useToast().toast({
//         title: 'Unable to find your location. Please ensure location services are enabled.',
//         variant: 'error',
//       })
//     },
//   )
// }
</script>

<template>
  <div class="absolute z-10 top-[calc(var(--header-plus-filters-height)+0.5rem)]">
    <Menubar class="bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border shadow-lg">
      <MenubarMenu>
        <MenubarTrigger class="gap-2">
          <WrenchIcon class="h-4 w-4" />
          Tools
        </MenubarTrigger>
        <MenubarContent>
          <MenubarSub>
            <MenubarSubTrigger>Navigate</MenubarSubTrigger>
            <MenubarSubContent>
              <MenubarItem disabled>
                Navigate to...
              </MenubarItem>
              <MenubarSeparator />
              <MenubarItem @click="zoomOut">
                <ZoomOutIcon class="mr-2 h-4 w-4" />
                Zoom Out
                <MenubarShortcut>-</MenubarShortcut>
              </MenubarItem>
              <MenubarItem @click="zoomIn">
                <ZoomInIcon class="mr-2 h-4 w-4" />
                Zoom In
                <MenubarShortcut>+</MenubarShortcut>
              </MenubarItem>
            </MenubarSubContent>
          </MenubarSub>
          <MenubarItem @click="zoomOut">
            <ZoomOutIcon class="mr-2 h-4 w-4" />
            Zoom Out
            <MenubarShortcut>-</MenubarShortcut>
          </MenubarItem>
          <MenubarItem @click="zoomIn">
            <ZoomInIcon class="mr-2 h-4 w-4" />
            Zoom In
            <MenubarShortcut>+</MenubarShortcut>
          </MenubarItem>
          <MenubarSeparator />

          <MenubarItem @click="toggleFullscreen">
            <MaximizeIcon class="mr-2 h-4 w-4" />
            Fullscreen
            <MenubarShortcut>⌘F</MenubarShortcut>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>

      <MenubarMenu>
        <MenubarTrigger class="gap-2">
          <EyeIcon class="h-4 w-4" />
          View
        </MenubarTrigger>
        <MenubarContent>
          <MenubarSub>
            <MenubarSubTrigger>
              <EyeIcon class="mr-2 h-4 w-4" />
              Map Controls
            </MenubarSubTrigger>
            <MenubarSubContent>
              <MenubarCheckboxItem v-model:checked="geosearch.showScale">
                Scale Control
              </MenubarCheckboxItem>
              <MenubarCheckboxItem v-model:checked="geosearch.showNavigation">
                Navigation Control
              </MenubarCheckboxItem>
              <MenubarCheckboxItem v-model:checked="geosearch.showFullscreen">
                Fullscreen Control
              </MenubarCheckboxItem>
              <!-- <MenubarCheckboxItem v-model:checked="geosearch.showGeolocate">
                Geolocate Control
              </MenubarCheckboxItem> -->
            </MenubarSubContent>
          </MenubarSub>
          <!-- <MenubarCheckboxItem v-model:checked="geosearch.showFilters">
            <FilterIcon class="mr-2 h-4 w-4" />
            Show Filters
          </MenubarCheckboxItem> -->
          <MenubarSeparator />
          <MenubarSub>
            <MenubarSubTrigger>
              <PaintbrushIcon class="mr-2 h-4 w-4" />
              Map Style
            </MenubarSubTrigger>
            <MenubarSubContent>
              <MenubarRadioGroup v-model="geosearch.currentStyle">
                <MenubarRadioItem value="default">
                  Default
                </MenubarRadioItem>
                <MenubarRadioItem value="streets">
                  Street Map
                </MenubarRadioItem>
                <MenubarRadioItem value="outdoors">
                  Outdoors
                </MenubarRadioItem>
                <MenubarRadioItem value="light">
                  Light Map
                </MenubarRadioItem>
                <MenubarRadioItem value="dark">
                  Dark Map
                </MenubarRadioItem>
                <MenubarRadioItem value="satellite">
                  Satellite
                </MenubarRadioItem>
                <MenubarRadioItem value="satelliteStreets">
                  Satellite Street Map
                </MenubarRadioItem>
                <MenubarRadioItem value="navigationDay">
                  Navigation Day
                </MenubarRadioItem>
                <MenubarRadioItem value="navigationNight">
                  Navigation Night
                </MenubarRadioItem>
              </MenubarRadioGroup>
              <MenubarSeparator />
              <MenubarItem inset disabled>
                Custom...
              </MenubarItem>
              <MenubarSeparator />
              <MenubarItem inset @click="geosearch.currentStyle = 'default'">
                Reset Style
              </MenubarItem>
            </MenubarSubContent>
          </MenubarSub>
          <!-- <MenubarSeparator />
          <MenubarItem inset disabled>
            Show Card View
          </MenubarItem> -->
          <MenubarSeparator />
          <MenubarItem @click="geosearch.showZoomLevelOverlay = !geosearch.showZoomLevelOverlay">
            <BugPlayIcon class="mr-2 h-4 w-4" />
            {{ geosearch.showZoomLevelOverlay ? 'Hide' : 'Show' }} Zoom Level
          </MenubarItem>
          <MenubarItem @click="geosearch.showDebugTools = !geosearch.showDebugTools">
            <BugPlayIcon class="mr-2 h-4 w-4" />
            {{ geosearch.showDebugTools ? 'Hide' : 'Show' }} Advanced
            <MenubarShortcut>⌘D</MenubarShortcut>
          </MenubarItem>
        </MenubarContent>
      </MenubarMenu>
    </Menubar>
  </div>
</template>
