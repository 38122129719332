<script setup lang="ts">
interface GeomapItem {
    type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: [number, number]
  }
  properties: not_deleted_construction_project_org_with_coordinates & {
    materialRequirements: Array<not_deleted_open_material_requirements>
  }
}

  const props = defineProps<{
    item: GeomapItem
  }>()

const { item } = toRefs(props)

const dayjs = useDayjs()
const activeOrgId = useActiveOrgId()

const img = useImage()
const fallbackImageUrl = computed(() => {
  const fallbackImageId = 'e3721c81-6890-45fd-ff8b-7fa89f743700'
  const srcImageUrl = getCloudflareImagesDeliveryUrl(fallbackImageId, 'profilebannerheader')
  const imgUrl = img(srcImageUrl, { height: 240, grayscale: true })
  return imgUrl
})

const requirementCounts = computed(() => {
  const requirements = item.value.properties.materialRequirements
  return {
    imports: requirements.filter((req: not_deleted_open_material_requirements) => req.type === 'import').length,
    exports: requirements.filter((req: not_deleted_open_material_requirements) => req.type === 'export').length,
  }
})

// Dayjs function which takes in a date and returns true or false depending on if the date is in the past
const isInPast = (date: string) => {
  return dayjs(date).isBefore(dayjs())
}
</script>

<template>
  <div v-if="item" class="h-full w-40 shadow-lg">
    <div class="relative h-20 rounded-t-md" :style="{ backgroundImage: `url(${fallbackImageUrl})` }">
      <div class="absolute top-1 right-1 flex items-center gap-1">
        <div
          v-if="requirementCounts.imports > 0"
          class="relative p-1 flex-1 rounded-full bg-[#3b82f6]/80 backdrop-blur-md shadow-lg group-hover:bg-[#3b82f6]/90 group-hover:shadow-xl transition-all duration-200"
        >
          <Icon
            name="soilicon:arrow-circle-broken-down" :style="{
              color: 'white',
            }" class="w-4 h-4"
          />
          <span
            class="absolute -bottom-1 -right-1 flex items-center justify-center w-4 h-4 text-[8px] font-medium bg-white text-blue-500 rounded-full"
          >
            {{ requirementCounts.imports }}
          </span>
        </div>
        <div
          v-if="requirementCounts.exports > 0"
          class="relative p-1 flex-1 rounded-full bg-[#ff3366]/80 backdrop-blur-md shadow-lg group-hover:bg-[#ff3366]/90 group-hover:shadow-xl transition-all duration-200"
        >
          <Icon
            name="soilicon-solid:arrows-up" :style="{
              color: 'white',
            }" class="w-4 h-4"
          />
          <span
            class="absolute -bottom-1 -right-1 flex items-center justify-center w-4 h-4 text-[8px] font-medium bg-white text-primary rounded-full"
          >
            {{ requirementCounts.exports }}
          </span>
        </div>
      </div>
    </div>

    <div class="bg-white dark:bg-gray-800 flex flex-col text-[10px] p-1 rounded-b-md">
      <div v-if="item.properties.type === 'export' || item.properties.type === 'import'">
        <div class="font-semibold">
          <p v-if="item.properties.subcategory">
            {{ item.properties.subcategory }}
          </p>
          <p v-if="item.properties.specificType">
            {{ item.properties.specificType }}
          </p>
          <div v-if="item.properties.volume" class="flex justify-between gap-1">
            <p>
              Volume
            </p>
            <p class="text-muted-foreground ">
              {{ item.properties.volume !== undefined ? new Intl.NumberFormat('en-US').format(item.properties.volume)
                : 'N/A' }}m<sup>3</sup>
            </p>
          </div>
          <div v-if="item.properties.requiredByDate" class="flex justify-between gap-1">
            <p>
              Required by
            </p>
            <p class="text-muted-foreground ">
              {{ dayjs(item.properties.requiredByDate).format('DD/MMM/YYYY') }}
            </p>
          </div>
        </div>
      </div>
      <div v-if="item.properties.type === 'project' || item.properties.type === 'global_project'">
        <div class="font-semibold">
          <p v-if="item.properties.name" class="text-xs">
            {{ item.properties.name }}
          </p>
          <p v-if="item.properties.address && activeOrgId" class="text-muted-foreground">
            {{ item.properties.address }}
          </p>
          <div v-if="activeOrgId">
            <div v-if="item.properties.startDate" class="flex justify-between gap-1">
              <p>
                Start date
              </p>
              <p class="text-muted-foreground ">
                {{ dayjs(item.properties.startDate).format('DD/MMM/YYYY') }}
              </p>
            </div>
            <div v-if="item.properties.endDate" class="flex justify-between gap-1">
              <p>
                End date
              </p>
              <p :class="isInPast(item.properties.endDate) ? 'text-destructive' : 'text-muted-foreground'">
                {{ dayjs(item.properties.endDate).format('DD/MMM/YYYY') }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
