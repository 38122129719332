<script setup lang="ts">
  import { ScrollArea } from '@/modules/ui/components/scroll-area'
import { useGeosearchStore } from '~/stores/geosearchAdvanced'

  const geosearch = useGeosearchStore()

  // Add interface for our GeoJSON Feature
  interface GeoFeature {
    type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: [number, number]
  }
  properties: not_deleted_construction_project_org_with_coordinates & {
    materialRequirements: Array<not_deleted_open_material_requirements>
  }
  }

  const scrollAreaRef = ref<InstanceType<typeof ScrollArea> | null>(null)

  const hoveredMarkerId = storeToRefs(geosearch).hoveredMarkerId

  // Add view type state
  const viewType = ref<'projects' | 'requirements'>('projects')

  // Modify limitedFeatures to properly handle requirements view
  const limitedFeatures = computed(() => {
    const features = (geosearch.geoJsonData?.features as GeoFeature[] || [])

    if (hoveredMarkerId.value) {
      const hoveredProject = features.find(feature => feature.properties.id === hoveredMarkerId.value)
      if (hoveredProject) {
        return hoveredProject.properties.materialRequirements.map((requirement: not_deleted_open_material_requirements) => ({
          type: 'Feature',
          geometry: hoveredProject.geometry,
          properties: {
            id: `${hoveredProject.properties.id}-${requirement.id}`,
            projectId: hoveredProject.properties.id,
            projectName: hoveredProject.properties.name,
            projectOrganization: hoveredProject.properties.organization,
            projectAddress: hoveredProject.properties.address,
            ...requirement,
          }
        }))
      }
      return []
    }

    // If no hover, show all projects or requirements based on viewType
    if (viewType.value === 'requirements') {
      return features.flatMap(feature =>
        feature.properties.materialRequirements.map((requirement: not_deleted_open_material_requirements) => ({
          type: 'Feature',
          geometry: feature.geometry,
          properties: {
            id: `${feature.properties.id}-${requirement.id}`,
            projectId: feature.properties.id,
            projectName: feature.properties.name,
            projectOrganization: feature.properties.organization,
            projectAddress: feature.properties.address,
            ...requirement,
          }
        })),
    )
    }

    return features
  })

  // Updated watch to use $el to access DOM element
  watch(limitedFeatures, () => {
    nextTick(() => {
      const viewport = scrollAreaRef.value?.$el?.querySelector('[data-radix-scroll-area-viewport]')
      if (viewport) {
        viewport.scrollTop = 0
      }
    })
  })
</script>

<template>
  <div>
    <div class="w-full flex sm:flex-col dark:bg-gray-900 z-10 bg-gray-100 rounded-lg bg-opacity-80 shadow-xl">
      <div class="w-full">
        <div class="p-2" :class="{ 'opacity-30 pointer-events-none': hoveredMarkerId }">
          <Tabs v-model="viewType">
            <TabsList class="grid w-full grid-cols-2">
              <TabsTrigger value="projects">
                Projects
              </TabsTrigger>
              <TabsTrigger value="requirements">
                Requirements
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>

        <ScrollArea ref="scrollAreaRef" class="h-[calc(100vh-var(--header-plus-filters-height)-80px)] w-full p-2 border border-foreground/10 rounded-b-lg">
          <div v-if="limitedFeatures.length > 0" v-auto-animate class="grid grid-cols-1 lg:grid-cols-2 gap-2">
            <div v-for="feature in limitedFeatures" :key="feature.properties.id">
              <GeomapItemCard
                :item="feature"
                :view-type="hoveredMarkerId ? 'requirements' : viewType"
              />
            </div>
          </div>
          <div v-else>
            <p class="text-sm mt-2 text-center">
              {{ hoveredMarkerId ? 'No requirements found for this project' : 'No results found' }}
            </p>
          </div>
        </ScrollArea>
      </div>
    </div>
  </div>
</template>
