<script setup lang="ts">
import { useGeosearchStore } from '~/stores/geosearchAdvanced'

const geosearch = useGeosearchStore()
const isOpen = ref(false)

// Helper function to set filter
const setFilter = (type: 'all' | 'imports' | 'exports') => {
  geosearch.filters.selectedRequirementType = type as 'all' | 'exports' | 'imports'
}

// Helper function to toggle market type
const toggleMarketType = (checked: boolean) => {
  geosearch.filters.marketType = checked ? 'internal' : 'open'
}

watch(() => geosearch.filters, () => {
  geosearch.fetchMapData()
}, { deep: true })

const activeOrgId = useActiveOrgId()
</script>

<template>
  <div class="h-[var(--map-filters-height)] flex items-center px-4">
    <div class="flex w-full justify-between items-center">
      <div class="flex flex-wrap gap-4 items-center">
        <Breadcrumb>
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/app/explore">
                <Icon name="soilicon-duotone:map-01" class="w-6 h-5" />
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>

        <PostHogFeatureFlag name="global_geocoder_map_filters_bar">
          <GeomapGlobalGeocoder />
        </PostHogFeatureFlag>

        <Popover v-model:open="isOpen">
          <PopoverTrigger as-child>
            <Button variant="outline" size="sm" class="flex items-center gap-2">
              Filters
              <Icon name="lucide:list-filter" class="h-4 w-4" />
            </Button>
          </PopoverTrigger>
          <PopoverContent class="p-2 ml-4 min-w-30">
            <Command>
              <CommandList>
                <CommandItem value="projects" @select="() => setFilter('all')">
                  <div class="flex items-center gap-2 justify-between text-xs text-muted-foreground w-full">
                    <div class="flex items-center gap-1">
                      <Icon name="heroicons:map" class="h-4 w-4" />
                      All projects
                    </div>
                    <Icon
                      v-if="geosearch.filters.selectedRequirementType === 'all'" name="heroicons:check"
                      class="ml-auto h-4 w-4"
                    />
                  </div>
                </CommandItem>
                <CommandItem value="imports" @select="() => setFilter('imports')">
                  <div class="flex items-center gap-2 justify-between text-xs text-muted-foreground w-full">
                    <div class="flex items-center gap-1">
                      <Icon name="soilicon:arrow-circle-broken-down" class="h-4 w-4 " />
                      Material Import
                    </div>
                    <Icon
                      v-if="geosearch.filters.selectedRequirementType === 'imports'" name="heroicons:check"
                      class="ml-auto h-4 w-4"
                    />
                  </div>
                </CommandItem>
                <CommandItem value="exports" @select="() => setFilter('exports')">
                  <div class="flex items-center gap-2 justify-between text-xs text-muted-foreground w-full">
                    <div class="flex items-center gap-1">
                      <Icon name="soilicon-solid:arrows-up" class="h-4 w-4" />
                      Material Export
                    </div>
                    <Icon
                      v-if="geosearch.filters.selectedRequirementType === 'exports'" name="heroicons:check"
                      class="ml-auto h-4 w-4"
                    />
                  </div>
                </CommandItem>
                <CommandItem disabled value="own-projects" @select="() => setFilter('all')">
                  <div class="flex items-center gap-2 justify-between text-xs text-muted-foreground w-full">
                    <div class="flex items-center gap-1">
                      <Icon name="soilicon-duotone:navigation-pointer-02" class="h-4 w-4" />
                      My Projects
                    </div>
                    <!-- <Icon
                      v-if="geosearch.filters.selectedRequirementType === 'all'" name="heroicons:check"
                      class="ml-auto h-4 w-4"
                    /> -->
                  </div>
                </CommandItem>
                <CommandItem disabled value="pinned-projects" @select="() => setFilter('all')">
                  <div class="flex items-center gap-2 justify-between text-xs text-muted-foreground w-full">
                    <div class="flex items-center gap-1">
                      <Icon name="soilicon-duotone:marker-pin-06" class="h-4 w-4" />
                      Pinned Projects
                    </div>
                    <!-- <Icon
                      v-if="geosearch.filters.selectedRequirementType === 'all'" name="heroicons:check"
                      class="ml-auto h-4 w-4"
                    /> -->
                  </div>
                </CommandItem>
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </div>

      <div class="flex items-center space-x-2">
        <div class="flex items-center space-x-2">
          <Label for="market-type" class="text-muted-foreground">
            Open Market
          </Label>
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger as-child>
                <Switch
                  id="market-type" :checked="geosearch.filters.marketType === 'internal'"
                  class="data-[state=checked]:bg-primary data-[state=unchecked]:bg-primary"
                  :disabled="!activeOrgId"
                  @update:checked="toggleMarketType"
                />
              </TooltipTrigger>
              <TooltipContent>
                <p v-if="!activeOrgId">
                  You must be a member of an organization to use this feature
                </p>
                <p v-else>
                  Toggle between open market and internal projects
                </p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
          <Label for="market-type" class="text-muted-foreground">
            Internal
          </Label>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
