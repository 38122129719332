<script setup lang="ts">
  import { CopyIcon, XIcon } from 'lucide-vue-next'
  import { useToast } from '~/modules/ui/components/toast'
  import { useGeosearchStore } from '~/stores/geosearchAdvanced'

const geosearch = useGeosearchStore()
const mapRef = useMapboxRef('mapMain')

const debugCardRef = ref<HTMLElement>()
const mapContainerRef = ref<HTMLElement>()
const initialPosition = { x: 20, y: window.innerHeight - 500 }

const { x, y, style: dragStyle } = useDraggable(debugCardRef, {
  initialValue: initialPosition,
  containerElement: mapContainerRef,
})

// Map Debug Info
const mapDebugInfo = computed(() => {
  if (!mapRef.value) {
    return null
  }

  return {
    zoom: mapRef.value.getZoom().toFixed(2),
    center: mapRef.value.getCenter(),
    bounds: mapRef.value.getBounds(),
    pitch: mapRef.value.getPitch(),
    bearing: mapRef.value.getBearing(),
    style: geosearch.currentStyle,
  }
})

const markersDebugInfo = computed(() => {
  return {
    marker1: geosearch.lnglat,
    marker2: null, // Replace with actual marker ref if needed
    customMarker: { lng: -2.2374, lat: 53.4808 },
  }
})

const mapPerformanceInfo = computed(() => {
  if (!mapRef.value) {
    return null
  }
  return {
    fps: 'N/A',
    renderingMode: mapRef.value.getRenderWorldCopies() ? '3D' : '2D',
    maxZoom: mapRef.value.getMaxZoom(),
    minZoom: mapRef.value.getMinZoom(),
    maxPitch: mapRef.value.getMaxPitch(),
    maxBounds: mapRef.value.getMaxBounds(),
    padding: mapRef.value.getPadding(),
  }
})

const mapLayerInfo = computed(() => {
  if (!mapRef.value) {
    return null
  }

  const layers = mapRef.value.getStyle()?.layers || []
  const sources = Object.keys(mapRef.value.getStyle()?.sources || {})

  return {
    layerCount: layers.length,
    visibleLayers: layers.filter((l) => {
      const layout = (l as any).layout
      return !layout?.visibility || layout.visibility === 'visible'
    }).length,
    sourceCount: sources.length,
    sources,
  }
})

const mapInteractionInfo = computed(() => {
  if (!mapRef.value) {
    return null
  }

  return {
    interactive: mapRef.value.getCanvas().style.cursor !== 'default',
    boxZoom: mapRef.value.boxZoom?.isEnabled?.() ?? 'N/A',
    scrollZoom: mapRef.value.scrollZoom?.isEnabled?.() ?? 'N/A',
    dragRotate: mapRef.value.dragRotate?.isEnabled?.() ?? 'N/A',
    dragPan: mapRef.value.dragPan?.isEnabled?.() ?? 'N/A',
    keyboard: mapRef.value.keyboard?.isEnabled?.() ?? 'N/A',
    doubleClickZoom: mapRef.value.doubleClickZoom?.isEnabled?.() ?? 'N/A',
    touchZoomRotate: mapRef.value.touchZoomRotate?.isEnabled?.() ?? 'N/A',
  }
})

const systemInfo = computed(() => ({
  viewport: {
    width: window.innerWidth,
    height: window.innerHeight,
  },
  devicePixelRatio: window.devicePixelRatio,
  userAgent: navigator.userAgent,
  platform: navigator.platform,
  language: navigator.language,
  online: navigator.onLine,
}))

watch([x, y], ([newX, newY]) => {
  geosearch.debugCardPosition = { x: newX, y: newY }
  localStorage.setItem('debugCardPosition', JSON.stringify(geosearch.debugCardPosition))
})

onMounted(() => {
  const savedPosition = localStorage.getItem('debugCardPosition')
  if (savedPosition) {
    const { x: savedX, y: savedY } = JSON.parse(savedPosition)
    const maxX = window.innerWidth - 500
    const maxY = window.innerHeight - 100
    geosearch.debugCardPosition = {
      x: Math.min(Math.max(0, savedX), maxX),
      y: Math.min(Math.max(0, savedY), maxY),
    }
    x.value = geosearch.debugCardPosition.x
    y.value = geosearch.debugCardPosition.y
  }
})

useEventListener(window, 'resize', () => {
  const maxX = window.innerWidth - 500
  const maxY = window.innerHeight - 100
  x.value = Math.min(x.value, maxX)
  y.value = Math.min(y.value, maxY)
})

function formatPosition(pos: any): string {
  if (!pos) {
    return 'Invalid position'
  }

  if (typeof pos === 'object' && 'lng' in pos) {
    return `${pos.lng?.toFixed(4)}, ${pos.lat?.toFixed(4)}`
  }

  const coords = Array.isArray(pos?.value) ? pos.value : Array.isArray(pos) ? pos : null
    if (coords) {
      return `${coords[0]?.toFixed(4)}, ${coords[1]?.toFixed(4)}`
    }

  return 'Invalid position'
}

const copyDebugInfo = () => {
  const debugInfo = {
    map: {
      zoom: mapDebugInfo.value?.zoom,
      center: {
        lng: mapDebugInfo.value?.center?.lng?.toFixed(6),
        lat: mapDebugInfo.value?.center?.lat?.toFixed(6),
      },
      bounds: {
        north: mapDebugInfo.value?.bounds?.getNorth()?.toFixed(6),
        south: mapDebugInfo.value?.bounds?.getSouth()?.toFixed(6),
        east: mapDebugInfo.value?.bounds?.getEast()?.toFixed(6),
        west: mapDebugInfo.value?.bounds?.getWest()?.toFixed(6),
      },
      pitch: mapDebugInfo.value?.pitch,
      bearing: mapDebugInfo.value?.bearing,
      style: geosearch.currentStyle,
    },
    performance: {
      fps: mapPerformanceInfo.value?.fps,
      renderingMode: mapPerformanceInfo.value?.renderingMode,
      maxZoom: mapPerformanceInfo.value?.maxZoom,
      minZoom: mapPerformanceInfo.value?.minZoom,
      maxPitch: mapPerformanceInfo.value?.maxPitch,
    },
    layers: {
      total: mapLayerInfo.value?.layerCount,
      visible: mapLayerInfo.value?.visibleLayers,
      sources: mapLayerInfo.value?.sourceCount,
      sourceList: mapLayerInfo.value?.sources,
    },
    interaction: {
      interactive: mapInteractionInfo.value?.interactive,
      boxZoom: mapInteractionInfo.value?.boxZoom,
      scrollZoom: mapInteractionInfo.value?.scrollZoom,
      dragRotate: mapInteractionInfo.value?.dragRotate,
      dragPan: mapInteractionInfo.value?.dragPan,
      keyboard: mapInteractionInfo.value?.keyboard,
      doubleClickZoom: mapInteractionInfo.value?.doubleClickZoom,
      touchZoomRotate: mapInteractionInfo.value?.touchZoomRotate,
    },
    markers: {
      marker1: {
        lng: geosearch.lnglat?.[0]?.toFixed(6),
        lat: geosearch.lnglat?.[1]?.toFixed(6),
      },
      customMarker: { lng: -2.2374, lat: 53.4808 },
    },
    system: {
      viewport: systemInfo.value.viewport,
      devicePixelRatio: systemInfo.value.devicePixelRatio,
      platform: systemInfo.value.platform,
      language: systemInfo.value.language,
      online: systemInfo.value.online,
      userAgent: systemInfo.value.userAgent,
    },
    timestamp: new Date().toISOString(),
  }

  navigator.clipboard.writeText(JSON.stringify(debugInfo, null, 2))

  useToast().toast({
    title: 'Debug info copied',
    variant: 'success',
  })

  useLogger().log('Debug info copied', '#map', '#debug', debugInfo)
}
</script>

<template>
  <div
    ref="debugCardRef"
    :style="[
      dragStyle,
      {
        position: 'fixed',
        zIndex: 1000,
        cursor: geosearch.isDragging ? 'grabbing' : 'grab',
        width: '500px',
      },
    ]"
    class="transition-all duration-200 hover:scale-102"
    :class="{
      'debug-card-dragging': geosearch.isDragging,
      'debug-card-hover': !geosearch.isDragging,
    }"
    @mousedown="geosearch.isDragging = true"
    @mouseup="geosearch.isDragging = false"
    @mouseleave="geosearch.isDragging = false"
  >
    <Card class="bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border shadow-lg">
      <CardHeader class="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle class="text-sm font-medium flex items-center gap-2">
          <div class="cursor-move text-muted-foreground">
            ⋮⋮
          </div>
          Debug Tools
        </CardTitle>
        <div class="flex gap-2">
          <Button variant="ghost" size="icon" class="h-4 w-4" @click="copyDebugInfo">
            <CopyIcon class="h-4 w-4" />
          </Button>
          <Button variant="ghost" size="icon" class="h-4 w-4" @click="geosearch.showDebugTools = false">
            <XIcon class="h-4 w-4" />
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div class="grid grid-cols-2 gap-4">
          <!-- Left Column -->
          <div class="space-y-3">
            <div>
              <h3 class="font-medium text-sm mb-1">
                Map Information
              </h3>
              <div class="text-xs space-y-0.5">
                <div class="grid grid-cols-2 gap-1">
                  <span class="text-muted-foreground">Zoom:</span>
                  <span>{{ mapDebugInfo?.zoom }}</span>
                  <span class="text-muted-foreground">Center:</span>
                  <span>{{ mapDebugInfo?.center?.lng.toFixed(4) }}, {{ mapDebugInfo?.center?.lat.toFixed(4) }}</span>
                  <span class="text-muted-foreground">Pitch:</span>
                  <span>{{ mapDebugInfo?.pitch }}°</span>
                  <span class="text-muted-foreground">Bearing:</span>
                  <span>{{ mapDebugInfo?.bearing }}°</span>
                  <span class="text-muted-foreground">Style:</span>
                  <span>{{ mapDebugInfo?.style }}</span>
                </div>
              </div>
            </div>

            <div>
              <h3 class="font-medium text-sm mb-1 flex items-center justify-between">
                Bounds
                <Button
                  variant="ghost"
                  size="icon"
                  class="h-4 w-4"
                  @click="() => {
                    const bounds = mapDebugInfo?.bounds
                    if (bounds) {
                      const boundsArray = [
                        bounds.getWest(),
                        bounds.getSouth(),
                        bounds.getEast(),
                        bounds.getNorth(),
                      ]
                      const { copy } = useClipboard()
                      copy(JSON.stringify(boundsArray))
                      useToast().toast({
                        title: 'Bounds copied',
                        description: '[west, south, east, north]',
                        variant: 'success',
                      })
                      useLogger().log('Bounds copied', '#map', '#debug', boundsArray)
                    }
                  }"
                >
                  <CopyIcon class="h-4 w-4" />
                </Button>
              </h3>
              <div class="text-xs space-y-0.5">
                <div class="grid grid-cols-2 gap-1">
                  <span class="text-muted-foreground">North:</span>
                  <span>{{ mapDebugInfo?.bounds?.getNorth().toFixed(4) }}°</span>
                  <span class="text-muted-foreground">South:</span>
                  <span>{{ mapDebugInfo?.bounds?.getSouth().toFixed(4) }}°</span>
                  <span class="text-muted-foreground">East:</span>
                  <span>{{ mapDebugInfo?.bounds?.getEast().toFixed(4) }}°</span>
                  <span class="text-muted-foreground">West:</span>
                  <span>{{ mapDebugInfo?.bounds?.getWest().toFixed(4) }}°</span>
                </div>
              </div>
            </div>

            <div>
              <h3 class="font-medium text-sm mb-1">
                Performance
              </h3>
              <div class="text-xs space-y-0.5">
                <div class="grid grid-cols-2 gap-1">
                  <span class="text-muted-foreground">FPS:</span>
                  <span>{{ mapPerformanceInfo?.fps }}</span>
                  <span class="text-muted-foreground">Rendering:</span>
                  <span>{{ mapPerformanceInfo?.renderingMode }}</span>
                  <span class="text-muted-foreground">Max Zoom:</span>
                  <span>{{ mapPerformanceInfo?.maxZoom }}</span>
                  <span class="text-muted-foreground">Min Zoom:</span>
                  <span>{{ mapPerformanceInfo?.minZoom }}</span>
                </div>
              </div>
            </div>
          </div>

          <!-- Right Column -->
          <div class="space-y-3">
            <div>
              <h3 class="font-medium text-sm mb-1">
                Marker Positions
              </h3>
              <div class="text-xs space-y-0.5">
                <div v-for="(pos, name) in markersDebugInfo" :key="name" class="grid grid-cols-2 gap-1">
                  <span class="text-muted-foreground">{{ name }}:</span>
                  <span>{{ formatPosition(pos) }}</span>
                </div>
              </div>
            </div>

            <div>
              <h3 class="font-medium text-sm mb-1">
                Layers & Sources
              </h3>
              <div class="text-xs space-y-0.5">
                <div class="grid grid-cols-2 gap-1">
                  <span class="text-muted-foreground">Total Layers:</span>
                  <span>{{ mapLayerInfo?.layerCount }}</span>
                  <span class="text-muted-foreground">Visible Layers:</span>
                  <span>{{ mapLayerInfo?.visibleLayers }}</span>
                  <span class="text-muted-foreground">Sources:</span>
                  <span>{{ mapLayerInfo?.sourceCount }}</span>
                </div>
              </div>
            </div>

            <div>
              <h3 class="font-medium text-sm mb-1">
                Interaction State
              </h3>
              <div class="text-xs space-y-0.5">
                <div class="grid grid-cols-2 gap-1">
                  <span class="text-muted-foreground">Interactive:</span>
                  <span>{{ mapInteractionInfo?.interactive }}</span>
                  <span class="text-muted-foreground">Box Zoom:</span>
                  <span>{{ mapInteractionInfo?.boxZoom }}</span>
                  <span class="text-muted-foreground">Scroll Zoom:</span>
                  <span>{{ mapInteractionInfo?.scrollZoom }}</span>
                  <span class="text-muted-foreground">Drag Rotate:</span>
                  <span>{{ mapInteractionInfo?.dragRotate }}</span>
                </div>
              </div>
            </div>

            <div>
              <h3 class="font-medium text-sm mb-1">
                System Info
              </h3>
              <div class="text-xs space-y-0.5">
                <div class="grid grid-cols-2 gap-1">
                  <span class="text-muted-foreground">Viewport:</span>
                  <span>{{ systemInfo.viewport.width }}x{{ systemInfo.viewport.height }}</span>
                  <span class="text-muted-foreground">Pixel Ratio:</span>
                  <span>{{ systemInfo.devicePixelRatio }}x</span>
                  <span class="text-muted-foreground">Online:</span>
                  <span>{{ systemInfo.online ? 'Yes' : 'No' }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  </div>
</template>

<style scoped>
.debug-card-dragging {
  transition: none !important;
}

.debug-card-hover {
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
}

.scale-102 {
  transform: scale(1.02);
}
</style>
