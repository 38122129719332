<script setup lang="ts">
import { useGeosearchStore } from '~/stores/geosearchAdvanced'

  const geosearch = useGeosearchStore()

interface GeomapItem {
    type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: [number, number]
  }
  properties: not_deleted_construction_project_org_with_coordinates & {
    materialRequirements: Array<not_deleted_open_material_requirements>
  }
}

  const props = defineProps<{
  item: GeomapItem
  viewType: 'projects' | 'requirements'
}>()

const { item, viewType } = toRefs(props)

const dayjs = useDayjs()
const activeOrgId = useActiveOrgId()
const itemColorClass = computed(() => {
    if (item.value.properties.type === 'project') {
      return '#18b4a8'
    }
    else if (item.value.properties.type === 'import') {
      return '#3b82f6'
    }
    else if (item.value.properties.type === 'export') {
      return '#ff3366'
    }
    else if (item.value.properties.type === 'global_project') {
      return '#f97316'
    }

  return '#6b7280'
})

const tailwindItemColorClass = computed(() => {
    if (item.value.properties.type === 'project') {
      return 'green-500'
    }
    else if (item.value.properties.type === 'import') {
      return 'blue-500'
    }
    else if (item.value.properties.type === 'export') {
      return 'primary'
    }
    else if (item.value.properties.type === 'global_project') {
      return 'orange-500'
    }

  return 'gray-500'
})

const img = useImage()
const fallbackImageUrl = computed(() => {
  const fallbackImageId = 'e3721c81-6890-45fd-ff8b-7fa89f743700'
  const srcImageUrl = getCloudflareImagesDeliveryUrl(fallbackImageId, 'profilebannerheader')
  const imgUrl = img(srcImageUrl, { height: 240, grayscale: true })
  return imgUrl
})

const formattedItemType = computed(() => {
  return item.value.properties.type.replace(/_/g, ' ')
})

// Update methods to use store actions
const onMarkerMouseEnter = (feature: GeomapItem) => {
    if (viewType.value === 'projects') {
      useEventCapture('user_hovered_project_card', {
        coordinates: feature.geometry.coordinates,
        projectId: feature.properties.id,
      })
      return geosearch.setHoveredCardId(feature.properties.id)
    }
  if (viewType.value === 'requirements') {
      useEventCapture('user_hovered_requirement_card', {
        coordinates: feature.geometry.coordinates,
      projectId: feature.properties.projectId,
      materialRequirementId: feature.properties.id,
    })
    return geosearch.setHoveredCardId(feature.properties.projectId)
  }
}

const onMarkerMouseLeave = () => {
  geosearch.setHoveredCardId(null)
}

const goToItem = async () => {
  try {
    if (viewType.value === 'requirements') {
      useEventCapture('user_clicked_requirement_card', {
        coordinates: item.value.geometry.coordinates,
        requirementId: item.value.properties.id,
        projectId: item.value.properties.projectId,
      })
        // Clear hover state
      geosearch.setHoveredCardId(null)
        if (geosearch.filters.marketType === 'internal') {
          // Navigate to the internal material requirement page view
          await navigateTo(`/app/material-requirement/${item.value.properties.id}`)
        }
        else {
          // Navigate to the open material requirement page view
          await navigateTo(`/app/explore/project/${item.value.properties.projectId}/requirement/${item.value.properties.id}`)
        }
      }
    else {
      useEventCapture('user_clicked_project_card', {
        coordinates: item.value.geometry.coordinates,
        projectId: item.value.properties.id,
      })
        // Clear hover state
        geosearch.setHoveredCardId(null)
      if (geosearch.filters.marketType === 'internal') {
          // Navigate to the internal project page view
          await navigateTo(`/app/manage/projects/${item.value.properties.id}`)
        }
        else {
          // Navigate to the open project page view
          await navigateTo(`/app/directory/rfq/${item.value.properties.id}`)
        }
    }
  }
  catch (error) {
    useLogger().error('Error navigating to item', {
      error,
      itemId: item.value.properties.id,
      viewType: viewType.value,
      })
  }
}

const requirementCounts = computed(() => {
  if (viewType.value === 'requirements') {
    return null
  }

  const requirements = item.value.properties.materialRequirements
  return {
    imports: requirements.filter((req: not_deleted_open_material_requirements) => req.type === 'import').length,
    exports: requirements.filter((req: not_deleted_open_material_requirements) => req.type === 'export').length,
  }
})

// Dayjs function which takes in a date and returns true or false depending on if the date is in the past
const isInPast = (date: string) => {
  return dayjs(date).isBefore(dayjs())
}
</script>

<template>
  <MotionGroup preset="fadeVisible" :duration="600">
    <li
      v-if="item"
      class="group w-full cursor-pointer bg-card hover:bg-card/80 rounded-xl h-full flex flex-col justify-between shadow-sm border-2 border-transparent hover:border-foreground/10 hover:shadow-md hover:scale-110 hover:border-2 hover:opacity-80 transition-all duration-200"
      @mouseenter="() => onMarkerMouseEnter(item)"
      @mouseleave="onMarkerMouseLeave"
      @click="goToItem"
    >
      <div class="relative h-32 bg-cover bg-center rounded-t-xl overflow-hidden" :style="{ backgroundImage: `url(${fallbackImageUrl})` }">
        <div class="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent" />
        <div class="absolute top-3 right-3 flex gap-2">
          <div
            v-if="viewType === 'requirements'" class="p-2 flex-1 rounded-full backdrop-blur-md shadow-lg group-hover:shadow-xl transition-all duration-200"
            :class="[`bg-[${itemColorClass}]/80`, `group-hover:bg-[${itemColorClass}]/90`]"
          >
            <Icon
              :name="item.properties.type === 'import' ? 'soilicon:arrow-circle-broken-down' : 'soilicon-solid:arrows-up'"
              :style="{
                color: 'white',
              }"
              class="w-5 h-5"
            />
          </div>

          <template v-if="viewType === 'projects' && requirementCounts">
            <div
              v-if="requirementCounts.imports > 0"
              class="relative p-2 flex-1 rounded-full bg-[#3b82f6]/80 backdrop-blur-md shadow-lg group-hover:bg-[#3b82f6]/90 group-hover:shadow-xl transition-all duration-200"
            >
              <Icon
                name="soilicon:arrow-circle-broken-down"
                :style="{
                  color: 'white',
                }"
                class="w-5 h-5"
              />
              <span
                class="absolute -bottom-1 -right-1 flex items-center justify-center w-4 h-4 text-[10px] font-medium bg-white text-blue-500 rounded-full"
              >
                {{ requirementCounts.imports }}
              </span>
            </div>
            <div
              v-if="requirementCounts.exports > 0"
              class="relative p-2 flex-1 rounded-full bg-[#ff3366]/80 backdrop-blur-md shadow-lg group-hover:bg-[#ff3366]/90 group-hover:shadow-xl transition-all duration-200"
            >
              <Icon
                name="soilicon-solid:arrows-up"
                :style="{
                  color: 'white',
                }"
                class="w-5 h-5"
              />
              <span
                class="absolute -bottom-1 -right-1 flex items-center justify-center w-4 h-4 text-[10px] font-medium bg-white text-primary rounded-full"
              >
                {{ requirementCounts.exports }}
              </span>
            </div>
          </template>
        </div>
      </div>
      <div class="flex flex-col gap-4 text-xs max-h-44 h-44" :class="activeOrgId ? 'h-44' : 'h-full'">
        <div v-if="viewType === 'requirements'" class="h-full p-2">
          <div class="gap-2 flex flex-col justify-between  h-full">
            <div>
              <p v-if="item.properties.subcategory" class="font-medium text-xs">
                {{ item.properties.subcategory }}
              </p>
              <p v-if="item.properties.specificType" class="text-muted-foreground text-xs line-clamp-1">
                {{ item.properties.specificType }}
              </p>
              <p v-if="item.properties.projectAddress && activeOrgId" class="text-muted-foreground text-[10px] line-clamp-2">
                {{ item.properties.projectAddress }}
              </p>
            </div>

            <div class="space-y-1.5 text-[10px]">
              <div class="flex justify-between items-center">
                <p class="text-muted-foreground">
                  Volume
                </p>
                <p class="font-medium">
                  {{ new Intl.NumberFormat('en-GB').format(item?.properties?.volume || 0) }}m<sup>3</sup>
                </p>
              </div>
              <div v-if="item.properties.requiredByDate" class="flex justify-between items-center">
                <p class="text-muted-foreground">
                  Required by
                </p>
                <div class="flex items-center gap-1">
                  <Icon v-if="isInPast(item.properties.requiredByDate)" name="soilicon:alert-triangle" class="text-destructive" />
                  <p :class="isInPast(item.properties.requiredByDate) ? 'text-destructive' : 'font-medium'">
                    {{ dayjs(item.properties.requiredByDate).format('DD/MMM/YYYY') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="viewType === 'projects'" class="p-2">
          <div class="gap-2 flex flex-col justify-between ">
            <div>
              <p v-if="item.properties.name" class="font-medium text-xs">
                {{ item.properties.name }}
              </p>
              <p v-if="item.properties.address && activeOrgId" class="text-muted-foreground text-[10px] line-clamp-2">
                {{ item.properties.address }}
              </p>
            </div>
            <div v-if="activeOrgId" class="space-y-1.5 text-[10px]">
              <div v-if="item.properties.startDate" class="flex justify-between items-center">
                <p class="text-muted-foreground">
                  Start date
                </p>
                <p class="font-medium">
                  {{ dayjs(item.properties.startDate).format('DD/MMM/YYYY') }}
                </p>
              </div>
              <div v-if="item.properties.endDate" class="flex justify-between items-center text-muted-foreground">
                <p>
                  End date
                </p>
                <div class="flex items-center gap-1">
                  <Icon v-if="isInPast(item.properties.endDate)" name="soilicon:alert-triangle" class="text-destructive" />
                  <p :class="isInPast(item.properties.endDate) ? 'text-destructive' : 'font-medium'">
                    {{ dayjs(item.properties.endDate).format('DD/MMM/YYYY') }}
                  </p>
                </div>
              </div>
            </div>
            <div v-if="item.properties.description" class="mt-2 flex flex-col gap-1">
              <p class="text-muted-foreground text-[10px]">
                Description
              </p>
              <p class=" text-[10px] line-clamp-2 font-medium">
                <MDC :value="item.properties.description" />
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="viewType === 'requirements'"
          class="rounded-b-xl text-xs font-medium text-white uppercase py-2 text-center"
          :class="`bg-${tailwindItemColorClass}`"
        >
          {{ formattedItemType }}
        </div>
      </div>
    </li>
  </MotionGroup>
</template>
