<script setup lang="ts">
import { useToast } from '~/modules/ui/components/toast'
import { useGeosearchStore } from '~/stores/geosearchAdvanced'

const geosearch = useGeosearchStore()
const mapContainerRef = ref<HTMLElement>()

const showAlert = () => {
    useLogger().log('You clicked on a marker', '#map')
  useToast().toast({
    title: 'You clicked on a marker',
    variant: 'success',
    duration: 3000,
  })
}

const onMapStyleLoad = (style: mapboxgl.StyleSpecification) => {
  useLogger().log('style loaded', '#map', { style })
}

const onMapResize = (map: mapboxgl.Map) => {
  useLogger().log('map resized GeomapWrapper', '#map', { map })
  geosearch.adjustMapPadding(map) // Adjust map padding to ensure panel is out of range
}

const activeOrgId = useActiveOrgId()
watch(() => activeOrgId, () => {
  geosearch.fetchMapData()
}, { deep: true })
</script>

<template>
  <div>
    <GeomapFilters />
  </div>

  <div
    id="map-container"
    ref="mapContainerRef"
    class="h-[calc(100vh-var(--header-plus-filters-height))] w-full z-10"
  >
    <div class="flex">
      <div
        class="w-full h-[calc(100vh-var(--header-plus-filters-height))]"
      >
        <div class="flex justify-center mr-2">
          <GeomapMenuBarSimple />
        </div>
        <GeomapDebugCard v-if="geosearch.showDebugTools" />
        <GeomapCore
          v-if="geosearch.showMap"
          :on-style-load="onMapStyleLoad"
          :on-resize="onMapResize"
          :on-marker-click="showAlert"
        />
      </div>

      <!-- <div class="w-2/5 h-[calc(100vh-var(--header-plus-filters-height))]">
       <GeomapPanel />
      </div> -->
    </div>
  </div>
</template>
