<script setup lang="ts">
import type { MapboxGeocoder } from '#components'
import type { PropType } from 'vue'
import { useGeosearchStore } from '~/stores/geosearchAdvanced'

const geosearch = useGeosearchStore()
const config = useRuntimeConfig()

const { isFeatureEnabled } = usePostHogFeatureFlag()

  const props = defineProps({
  onStyleLoad: {
    type: Function as PropType<(style: mapboxgl.StyleSpecification) => void>,
    required: true,
  },
  onResize: {
    type: Function as PropType<(map: mapboxgl.Map) => void>,
    required: true,
  },
  onMarkerClick: {
    type: Function as PropType<() => void>,
    required: true,
  },
})

const { onStyleLoad, onResize } = toRefs(props)

// Geocoder setup
const geocoderRef = ref<InstanceType<typeof MapboxGeocoder>>()

const onGeocoderResult = (result: MapboxGeocoder.Result) => {
  useLogger().log(`geocoder result - ${result.place_name}`, '#map', '#geocoder', { result })
  geosearch.setGeocoderResult(result)
}

// Map style
const mapStyle = computed(() => ({
  style: geosearch.style,
  center: [
    config.public.mapbox.defaultStartingPosition[0],
    config.public.mapbox.defaultStartingPosition[1],
  ] as [number, number],
  zoom: config.public.mapbox.defaultStartingZoom,
  minZoom: 4.5,
  maxBounds: [[-20.555585259907872, 44.46743010864236], [10.904058523295703, 62.413786121131665]] as [[number, number], [number, number]],
  maxZoom: geosearch.zoomLevelLimit || undefined,
}))

const mapInstance = ref<mapboxgl.Map | null>(null)

const onMapLoad = (map: mapboxgl.Map) => {
  mapInstance.value = map
  geosearch.handleMapUpdate(map)
  geosearch.adjustMapPadding(map) // Adjust map padding to ensure panel is out of range
}

const onMoveEnd = (e: { target: mapboxgl.Map }) => {
  geosearch.handleMapUpdate(e.target)
}
</script>

<template>
  <div
    id="map-container"
    class="relative w-full bg-gradient-to-br from-slate-900 via-slate-800 to-slate-900 dark:from-slate-950 dark:via-slate-900 dark:to-slate-950"
  >
    <client-only>
      <MapboxMap
        map-id="mapMain" :options="mapStyle" class="h-[calc(100vh-var(--header-plus-filters-height))]"
        @load="onMapLoad" @styleload="onStyleLoad" @resize="onResize" @moveend="onMoveEnd"
      >
        <!-- Map Layers -->
        <!-- Markers -->
        <GeomapMarkers v-if="mapInstance" :map="mapInstance" />
        <!-- Controls -->
        <MapboxGeocoder
          ref="geocoderRef" :model-value="geosearch.geocoderRes || undefined" position="top-left"
          :options="{
            version: 'v6',
            proximity: 'ip',
            countries: 'gb',
          }" @result="onGeocoderResult"
        />
        <!-- Map Controls -->
        <MapboxAttributionControl />
        <MapboxScaleControl v-if="isFeatureEnabled('map_main_scale_control') && geosearch.showScale" position="bottom-left" />
        <MapboxNavigationControl v-if="isFeatureEnabled('map_main_navigation_control') && geosearch.showNavigation" position="bottom-left" />
        <MapboxFullscreenControl v-if="isFeatureEnabled('map_main_fullscreen_control') && geosearch.showFullscreen" position="bottom-left" />
        <MapboxGeolocateControl v-if="isFeatureEnabled('map_main_geolocate_control') && geosearch.showGeolocate" position="bottom-left" />
        <!-- Map Cards Panel -->
        <div class="absolute right-0 top-0 w-2/5 h-[calc(100vh-var(--header-plus-filters-height))] mt-4 mr-2 mb-4">
          <GeomapPanel />
        </div>
        <!-- Map Overlays -->

        <GeomapOverlays />
      </MapboxMap>
    </client-only>
  </div>
</template>

<style>
.mapboxgl-map {
  height: calc(100vh - var(--header-plus-filters-height)) !important;
}

.mapboxgl-ctrl-attrib {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.mapboxgl-ctrl-logo {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.mapboxgl-popup-content {
  padding: 10px;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}
</style>
