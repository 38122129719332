<script setup lang="ts">
import { useGeosearchStore } from '~/stores/geosearchAdvanced'

const props = defineProps({
  map: {
    type: Object as PropType<mapboxgl.Map | null>,
    required: true,
    }
})

const { map } = toRefs(props)

const geosearch = useGeosearchStore()

// Add interface for our GeoJSON Feature
interface GeoFeature {
  type: 'Feature'
  geometry: {
    type: 'Point'
    coordinates: [number, number]
  }
  properties: not_deleted_construction_project_org_with_coordinates & {
    materialRequirements: Array<not_deleted_open_material_requirements>
  }
}

const goToItem = async (feature: GeoFeature) => {
  useEventCapture('user_clicked_project_marker', {
    coordinates: feature.geometry.coordinates,
    projectId: feature.properties.id,
  })

  // Clear hover state
  geosearch.setHoveredMarkerId(null)

  if (geosearch.filters.marketType === 'internal') {
    // Navigate to the internal project page view
    await navigateTo(`/app/manage/projects/${feature.properties.id}`)
  }
  else {
    // Navigate to the open project page view
    await navigateTo(`/app/directory/rfq/${feature.properties.id}`)
  }
}

// Popup ref
const popup = useMapboxPopupRef('')

// Watch popup changes for logging
watch(popup, (newPopup, oldPopup) => {
  useLogger().log('Popup changed', '#map', '#popup', {
    new: {
      lngLat: newPopup?.getLngLat(),
      isOpen: newPopup?.isOpen(),
      content: newPopup?.getElement()?.textContent,
    },
    old: {
      lngLat: oldPopup?.getLngLat(),
      isOpen: oldPopup?.isOpen(),
      content: oldPopup?.getElement()?.textContent,
    },
  })
}, { deep: true })

// Computed property to check if the feature has both imports and exports, or one of them, it should return either 'all', 'imports' or 'exports'
const getProjectTradeType = (feature: GeoFeature) => {
  if (!feature.properties.materialRequirements.length) {
    return 'none'
  }

  if (feature.properties.materialRequirements.some((req: not_deleted_open_material_requirements) => req.type === 'import')
      && feature.properties.materialRequirements.some((req: not_deleted_open_material_requirements) => req.type === 'export')) {
    return 'all'
  }
  return feature.properties.materialRequirements[0].type
}

const hoveredMarkerId = storeToRefs(geosearch).hoveredMarkerId
const hoveredCardId = storeToRefs(geosearch).hoveredCardId

// Update methods to use store actions
const onMarkerMouseEnter = (feature: GeoFeature) => {
  useEventCapture('user_hovered_project_marker', {
    coordinates: feature.geometry.coordinates,
    projectId: feature.properties.id,
  })
  geosearch.setHoveredMarkerId(feature.properties.id)
}

const onMarkerMouseLeave = () => {
  geosearch.setHoveredMarkerId(null)
}
</script>

<template>
  <!-- Custom markers -->
  <template v-if="map">
    <div v-for="feature in (geosearch.geoJsonData?.features as GeoFeature[])" :key="feature?.properties?.id">
      <GeomapCustomMarker
        v-if="feature && feature.properties"
        :lnglat="feature.geometry.coordinates"
        color="#1b998b"
        :map="map"
        :on-click="() => goToItem(feature)"
        :is-hovered="hoveredCardId === feature.properties.id"
        :type="getProjectTradeType(feature)"
        @mouseenter="() => onMarkerMouseEnter(feature)"
        @mouseleave="onMarkerMouseLeave"
      >
        <MapboxDefaultPopup
          v-if="hoveredMarkerId === feature.properties.id"
          :popup-id="`popup-${feature.properties.id}`"
          :lnglat="feature.geometry.coordinates"
          :options="{
            closeOnClick: false,
            className: 'custom-popup-no-padding ',
            offset: 10,
          }"
        >
          <GeomapPopoverCard :item="feature" />
        </MapboxDefaultPopup>
      </GeomapCustomMarker>
    </div>
  </template>
</template>

<style>
.custom-popup-no-padding .mapboxgl-popup-content {
  padding: 0;
  border-radius: 0.5rem;
}
</style>
