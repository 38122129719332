<script setup lang="ts">
import mapboxgl from 'mapbox-gl'

const props = defineProps({
  lnglat: {
    type: Array as unknown as PropType<[number, number]>,
    required: true,
    },
  map: {
    type: Object as PropType<mapboxgl.Map>,
    required: true,
    },
  onClick: {
    type: Function as PropType<() => void>,
    required: true,
    },
  type: {
    type: String,
    default: 'all',
  },
  isHovered: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['mouseenter', 'mouseleave'])

const { type } = toRefs(props)

const markerEl = ref<HTMLDivElement | null>(null)
const marker = ref<mapboxgl.Marker | null>(null)

const createMarker = () => {
  if (markerEl.value && props.map) {
    const el = document.createElement('div')
    el.className = 'custom-marker'

    const styleSheet = document.createElement('style')
    styleSheet.textContent = `
      @keyframes bounce {
        0%, 100% { transform: translateY(0); }
        50% { transform: translateY(-10px); }
      }
      .marker-bounce {
        animation: bounce 1s ease-in-out infinite;
      }
    `
    document.head.appendChild(styleSheet)

    if (props.isHovered) {
      el.innerHTML = `
        <svg
          width="${type.value === 'global_project' ? '24' : '32'}"
          height="${type.value === 'global_project' ? '24' : '32'}"
          viewBox="0 0 24 24"
          fill="${type.value === 'all'
            ? 'url(#gradient)'
            : type.value === 'import'
              ? '#3b82f6'
              : type.value === 'none'
                ? '#f97316' // Orange color for 'none' type
                : '#ff3366'}"
          class="marker-bounce"
          style="filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25)); transition: all 0.2s ease;"
        >
          ${type.value === 'all'
            ? `
            <defs>
              <linearGradient id="gradient" x1="0" x2="1" y1="0" y2="0">
                <stop offset="0%" stop-color="#3b82f6"/>
                <stop offset="50%" stop-color="#3b82f6"/>
                <stop offset="50%" stop-color="#ff3366"/>
                <stop offset="100%" stop-color="#ff3366"/>
              </linearGradient>
            </defs>
          `
            : ''}
          <path d="M12 0C7.58 0 4 3.58 4 8c0 5.25 8 13 8 13s8-7.75 8-13c0-4.42-3.58-8-8-8zm0 11c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z"/>
        </svg>
      `
    }
    else {
      el.innerHTML = `
        <div style="
          width: ${type.value === 'global_project' ? '12px' : '16px'};
          height: ${type.value === 'global_project' ? '12px' : '16px'};
          background: ${type.value === 'all'
            ? 'linear-gradient(90deg, #3b82f6 50%, #ff3366 50%)'
            : type.value === 'import'
              ? '#3b82f6'
              : type.value === 'none'
                ? '#f97316' // Orange color for 'none' type
                : '#ff3366'};
          border-radius: 50%;
          border: ${type.value === 'global_project' ? '2px' : '1px'} solid white;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
          transition: all 0.2s ease;
        "></div>
      `
    }

    el.style.cssText = `
      display: block;
      filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.25));
      cursor: pointer;
    `

    el.addEventListener('mouseenter', () => {
      (el.firstElementChild as HTMLElement).style.transform = 'scale(1.1)'
    })

    el.addEventListener('mouseleave', () => {
      (el.firstElementChild as HTMLElement).style.transform = 'scale(1)'
    })

    marker.value = new mapboxgl.Marker({
      element: el,
      anchor: 'bottom',
    })
      .setLngLat(props.lnglat)
      .addTo(props.map)

    el.addEventListener('click', props.onClick)
    el.addEventListener('mouseenter', () => emit('mouseenter'))
    el.addEventListener('mouseleave', () => emit('mouseleave'))
  }
}

onMounted(createMarker)

onUnmounted(() => {
  if (marker.value) {
    marker.value.remove()
  }
  if (markerEl.value) {
    markerEl.value.removeEventListener('click', props.onClick)
    markerEl.value.removeEventListener('mouseenter', () => emit('mouseenter'))
    markerEl.value.removeEventListener('mouseleave', () => emit('mouseleave'))
  }
})

watch(() => props.lnglat, (newLngLat) => {
  if (marker.value) {
    marker.value.setLngLat(newLngLat)
  }
})

watch(() => props.isHovered, () => {
  if (marker.value) {
    marker.value.remove()
    createMarker()
  }
})

watch(() => props.type, () => {
  if (marker.value) {
    marker.value.remove()
    createMarker()
  }
})
</script>

<template>
  <div ref="markerEl">
    <slot />
  </div>
</template>
