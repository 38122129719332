import type { FillLayerSpecification, GeoJSONSourceSpecification } from 'mapbox-gl'
import { defineStore } from 'pinia'

export const useGeosearchStore = defineStore('geosearch', () => {
  const config = useRuntimeConfig()

  // Map visibility and basic state
  const showMap = ref(true)
  const enabled = ref(true)
  const height = ref(800)

  // Map source and layer
  const source = ref<GeoJSONSourceSpecification>({
    type: 'geojson',
    data: '/test.geojson',
  })
  const layerRef = ref<FillLayerSpecification>({
    source: 'geojson',
    id: 'geojson-layer',
    type: 'fill',
    paint: {
      'fill-color': 'rgb(0,0,0)',
    },
  })

  // Marker state
  const lnglat = ref([-1.5491, 53.8008] as [number, number])

  // Map styles
  const mapStyles = {
    default: config.public.mapbox.defaultMapStyle,
    streets: 'mapbox://styles/mapbox/streets-v12',
    light: 'mapbox://styles/mapbox/light-v11',
    dark: 'mapbox://styles/mapbox/dark-v11',
    satellite: 'mapbox://styles/mapbox/satellite-v9',
    satelliteStreets: 'mapbox://styles/mapbox/satellite-streets-v12',
    outdoors: 'mapbox://styles/mapbox/outdoors-v12',
    navigationDay: 'mapbox://styles/mapbox/navigation-day-v1',
    navigationNight: 'mapbox://styles/mapbox/navigation-night-v1',
  } as const

  const currentStyle = ref<keyof typeof mapStyles>('default')
  const style = computed(() => mapStyles[currentStyle.value])

  // Search and geocoder state
  const showSearchCard = ref(false)
  const locationSearch = ref('')

  // UI Controls visibility
  const showScale = ref(true)
  const showNavigation = ref(true)
  const showFullscreen = ref(true)
  const showGeolocate = ref(true)
  const showDebugTools = ref(false)
  const showFilters = ref(true)
  const showZoomLevelOverlay = ref(false)

  // Filters state
  const filters = ref({
    showProjects: true,
    showSuppliers: true,
    showGlobalProjects: false,
    selectedRequirementType: 'all' as 'all' | 'exports' | 'imports',
    marketType: 'open' as 'open' | 'internal',
  })

  // Map padding state
  const mapPadding = ref({
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  })

  // Add a new state for GeoJSON data
  const geoJsonData = ref<GeoJSON.FeatureCollection>({
    type: 'FeatureCollection',
    features: [],
  })

  // Add a state for map bounds
  const mapBounds = ref<[[number, number], [number, number]]>([[0, 0], [0, 0]])

  // Add hoveredMarkerId state
  const hoveredMarkerId = ref<string | null>(null)
  // Add hoveredCardId state
  const hoveredCardId = ref<string | null>(null)

  async function fetchMapData() {
    const data = await $fetch<GeoJSON.FeatureCollection>('/api/v2/geo/map-data/advanced', {
      params: {
        showProjects: filters.value.showProjects,
        showSuppliers: filters.value.showSuppliers,
        showGlobalProjects: filters.value.showGlobalProjects,
        selectedRequirementType: filters.value.selectedRequirementType,
        marketType: filters.value.marketType,
        minLng: mapBounds.value[0][0],
        minLat: mapBounds.value[0][1],
        maxLng: mapBounds.value[1][0],
        maxLat: mapBounds.value[1][1],
      },
      headers: useRequestHeaders(['cookie']),
    })
    geoJsonData.value = data
  }

  // Add a function to update map bounds
  function updateMapBounds(bounds: [[number, number], [number, number]]) {
    mapBounds.value = bounds
  }

  // Debug card position
  const debugCardPosition = ref({ x: 20, y: window.innerHeight - 500 })
  const isDragging = ref(false)

  // Add geocoder result state
  const geocoderRes = ref<MapboxGeocoder.Result | null>(null)

  // Optional: Add an action to update geocoder result
  function setGeocoderResult(result: MapboxGeocoder.Result | null) {
    geocoderRes.value = result
    useLogger().log('Geocoder result updated', '#map', '#geocoder', { result })
  }

  // Actions
  function toggleMap() {
    showMap.value = !showMap.value
    useLogger().log('Map toggled', '#map', { visible: showMap.value })
  }

  function changeData() {
    source.value = {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [[
            [-2.3632, 53.3997],
            [-2.1568, 53.3997],
            [-2.0151, 53.4589],
            [-2.0872, 53.5405],
            [-2.1678, 53.5889],
            [-2.3846, 53.5889],
            [-2.3632, 53.3997],
          ]],
        },
        properties: {},
      },
    }
  }

  function changeLngLat() {
    lnglat.value = [lnglat.value[0] + 1, lnglat.value[1] + 1]
  }

  function changeColor() {
    const r = Math.floor(Math.random() * 256)
    const g = Math.floor(Math.random() * 256)
    const b = Math.floor(Math.random() * 256)
    layerRef.value.paint!['fill-color'] = `rgb(${r}, ${g}, ${b})`
  }

  function changeHeight() {
    height.value = height.value - 100
  }

  // Add actions to update hoveredMarkerId
  function setHoveredMarkerId(id: string | null) {
    hoveredMarkerId.value = id
  }

  // Add actions to update hoveredCardId
  function setHoveredCardId(id: string | null) {
    hoveredCardId.value = id
  }

  // Computed
  const sidebarWidth = computed(() => showFilters.value ? 'w-64' : 'w-0')

  // Add a method to handle all map-related updates in one place
  function handleMapUpdate(map: mapboxgl.Map) {
    const bounds = map.getBounds()
    if (!bounds) { return }

    updateMapBounds([
      [bounds.getWest(), bounds.getSouth()],
      [bounds.getEast(), bounds.getNorth()],
    ])
    fetchMapData()
  }

  // Get the mainMap instance (eg getMapInstance('mainMap'))
  const getMapInstance = (mapId: string) => {
    return useMapbox(mapId, (map) => {
      // Do whatever with map here
      return map
    })
  }

  // Function to adjust map padding to ensure panel is out of range
  const adjustMapPadding = (map: mapboxgl.Map) => {
    // Calculate the panel width as 2/5 of the viewport width plus 20px
    const panelWidth = (window.innerWidth * 2 / 5) + 20

    // Put the panel width into the mapPadding state
    mapPadding.value.right = panelWidth

    // Add 28px to the top for map tools
    mapPadding.value.top = 10

    // Set the padding for the map to ensure the panel is out of range
    map.setPadding(mapPadding.value)

    if (usePostHogFeatureFlag().isFeatureEnabled('map_show_padding_debug')) {
      // Shows map padding debug lines on the map view
      map.showPadding = true
    }

    useLogger().log('map padding adjusted', '#map', '#adjustMapPadding', { map })
  }

  // Map zoom level limit
  const zoomLevelLimit = computed(() => {
    return usePostHogFeatureFlag()?.getFeatureFlag('geomap_zoom_in_limit_non_pro')?.payload?.zoomLimit || 15
  })

  return {
    // State
    showMap,
    enabled,
    height,
    source,
    layerRef,
    lnglat,
    mapStyles,
    currentStyle,
    style,
    showSearchCard,
    locationSearch,
    showScale,
    showNavigation,
    showFullscreen,
    showGeolocate,
    showDebugTools,
    showFilters,
    filters,
    geoJsonData,
    debugCardPosition,
    isDragging,
    sidebarWidth,
    geocoderRes,
    hoveredMarkerId,
    hoveredCardId,
    showZoomLevelOverlay,
    // Computed from feature flag
    zoomLevelLimit,

    // Actions
    fetchMapData,
    updateMapBounds,
    setGeocoderResult,
    toggleMap,
    changeData,
    changeLngLat,
    changeColor,
    changeHeight,
    setHoveredMarkerId,
    setHoveredCardId,
    handleMapUpdate,
    getMapInstance,
    adjustMapPadding,
  }
})

export type GeosearchAdvancedMapState = ReturnType<typeof useGeosearchStore>

// Enable HMR
if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useGeosearchStore, import.meta.hot))
}
